

























































































































































































































































































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface SelectOption {
  value: string | any;
  label: string;
}

@Component({
  components: {}
})
export default class FormularioCultura extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private cerrado = true;
  private formatRut: string = "";
  private dataForm: any = {
    nombre: "",
    rut: "",
    fecha_nacimiento: "",
    telefono: "",
    email: "",
    taller: "",
    dias_horario: ""
  };
  private formattedFecha = "";
  private cv: File | null = null;
  private acreditacion: File | null = null;
  private planificacion: File | null = null;
  private certificado_menores: File | null = null;
  private certificado_vulnerables: File | null = null;

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  @Watch("formatRut")
  private formatRutWatch(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.dataForm.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formatRut = `${rut}-${dv}`;
    } else {
      this.formatRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  @Watch("formattedFecha")
  private edadWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    } else if (ua.indexOf("mozilla") != -1) {
      //Usa Mozilla
      birthDate = (newValue.substring(6, 10) +
        "-" +
        newValue.substring(3, 5) +
        "-" +
        newValue.substring(0, 2) +
        "T12:00:00") as any;
    }
    if (birthDate && birthDate.length <= 18) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      this.dataForm.fecha_nacimiento = null;
      return;
    }
    let diff;
    if (birthDate instanceof Date) {
      diff = now.getTime() - birthDate.getTime();
      this.dataForm.fecha_nacimiento = birthDate;
    } else {
      diff = now.getTime() - new Date(birthDate).getTime();
      this.dataForm.fecha_nacimiento = new Date(birthDate);
    }
  }

  private mounted() {
    document.title =
      "Formulario Postulación a Talleres 2025 - Ilustre Municipalidad de Punta Arenas";
  }

  private checkFile(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["pdf", "PDF"];
    if (target.files && target.files[0]) {
      for (const x in extensions) {
        if (target.files[0].type.includes(extensions[x])) {
          flag = true;
        }
      }
      if (target.files[0].size > 5 * 1024 * 1024) {
        Notify.create({
          color: "negative",
          message: "El archivo excede el máximo (5mb).",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        if (target.name === "cv") {
          this.cv = null;
        } else if (target.name === "acreditacion") {
          this.acreditacion = null;
        } else if (target.name === "planificacion") {
          this.planificacion = null;
        } else if (target.name === "certificado_menores") {
          this.certificado_menores = null;
        } else if (target.name === "certificado_vulnerables") {
          this.certificado_vulnerables = null;
        }
        return;
      } else if (!flag) {
        Notify.create({
          color: "negative",
          message: "El archivo debe ser formato PDF.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        if (target.name === "cv") {
          this.cv = null;
        } else if (target.name === "acreditacion") {
          this.acreditacion = null;
        } else if (target.name === "planificacion") {
          this.planificacion = null;
        } else if (target.name === "certificado_menores") {
          this.certificado_menores = null;
        } else if (target.name === "certificado_vulnerables") {
          this.certificado_vulnerables = null;
        }
        return;
      } else if (target.files[0].size <= 0 || !flag) {
        if (target.name === "cv") {
          this.cv = null;
        } else if (target.name === "acreditacion") {
          this.acreditacion = null;
        } else if (target.name === "planificacion") {
          this.planificacion = null;
        } else if (target.name === "certificado_menores") {
          this.certificado_menores = null;
        } else if (target.name === "certificado_vulnerables") {
          this.certificado_vulnerables = null;
        }
      } else {
        if (target.name === "cv") {
          this.cv = target.files[0];
        } else if (target.name === "acreditacion") {
          this.acreditacion = target.files[0];
        } else if (target.name === "planificacion") {
          this.planificacion = target.files[0];
        } else if (target.name === "certificado_menores") {
          this.certificado_menores = target.files[0];
        } else if (target.name === "certificado_vulnerables") {
          this.certificado_vulnerables = target.files[0];
        }
      }
    } else {
      if (target.name === "cv") {
        this.cv = null;
      } else if (target.name === "acreditacion") {
        this.acreditacion = null;
      } else if (target.name === "planificacion") {
        this.planificacion = null;
      } else if (target.name === "certificado_menores") {
        this.certificado_menores = null;
      } else if (target.name === "certificado_vulnerables") {
        this.certificado_vulnerables = null;
      }
    }
  }

  private enviar() {
    if (
      this.dataForm.rut == "" ||
      this.validateRut(this.dataForm.rut) == false
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.nombre.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su nombre completo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.fecha_nacimiento == null ||
      !this.checkFecha(this.dataForm.fecha_nacimiento)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor ingrese su fecha de nacimiento",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.email.trim() == "" ||
      !this.validEmail(this.dataForm.email)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (
      this.dataForm.fecha_nacimiento == "" ||
      this.dataForm.fecha_nacimiento == null ||
      !this.checkFecha(this.dataForm.fecha_nacimiento)
    ) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        if (this.dataForm.fecha_nacimiento.getFullYear() < 1890) {
          Notify.create({
            color: "negative",
            message:
              "Por favor, ingrese una fecha en formato válido (DD-MM-AAAA)",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      }
    }

    if (this.dataForm.telefono.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.telefono.length < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.taller.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese el nombre del taller.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.dataForm.dias_horario.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese los días y horarios a impartir el taller.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.planificacion == null) {
      Notify.create({
        color: "negative",
        message:
          "Por favor, debe adjuntar planificación Semestral (max 5mb formato pdf)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.cv == null) {
      Notify.create({
        color: "negative",
        message:
          "Por favor, debe adjuntar CV, Copia de diplomas o certificados de cursos, talleres, diplomados, magister o cartas que acrediten la expertiz (max 5mb formato pdf)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.acreditacion == null) {
      Notify.create({
        color: "negative",
        message:
          "Por favor, debe adjuntar Documentación o Fotografías, que permita dar cuenta de la experiencia en la disciplina (max 5mb formato pdf)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.certificado_menores == null) {
      Notify.create({
        color: "negative",
        message:
          "Por favor, debe adjuntar Certificado de inhabilidades para trabajar con menores de edad (max 5mb formato pdf)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.certificado_vulnerables == null) {
      Notify.create({
        color: "negative",
        message:
          "Por favor, debe adjuntar Certificado de inhabilidad para trabajar  con personas vulnerables (max 5mb formato pdf)",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });

    const data = new FormData();
    if (this.cv) {
      const pFile = this.cv as Blob;
      data.append("cv", pFile);
    } else {
      const pFile = new File(["file"], "Cv") as Blob;
      data.append("cv", pFile);
    }
    if (this.acreditacion) {
      const pFile = this.acreditacion as Blob;
      data.append("acreditacion", pFile);
    } else {
      const pFile = new File(["file"], "Acreditacion") as Blob;
      data.append("acreditacion", pFile);
    }
    if (this.planificacion) {
      const pFile = this.planificacion as Blob;
      data.append("planificacion", pFile);
    } else {
      const pFile = new File(["file"], "Planificacion") as Blob;
      data.append("planificacion", pFile);
    }
    if (this.certificado_menores) {
      const pFile = this.certificado_menores as Blob;
      data.append("certificado_menores", pFile);
    } else {
      const pFile = new File(["file"], "Certificado_menores") as Blob;
      data.append("certificado_menores", pFile);
    }
    if (this.certificado_vulnerables) {
      const pFile = this.certificado_vulnerables as Blob;
      data.append("certificado_vulnerables", pFile);
    } else {
      const pFile = new File(["file"], "Certificado_vulnerables") as Blob;
      data.append("certificado_vulnerables", pFile);
    }
    for (const prop in this.dataForm) {
      if (this.dataForm.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.dataForm[prop] || this.dataForm[prop] === 0) {
          // @ts-ignore
          data.append(prop, this.dataForm[prop]);
        }
      }
    }
    this.$axios
      .post("formulario/cultura", data, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message: "Se ha enviado correctamente el formulario.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.routerGo("Home");
        return;
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un error, por favor intente nuevamente.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      });
  }

  private checkFecha(d: any) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
